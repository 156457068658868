import React, { createContext, useContext, useReducer, ReactNode, useCallback, useMemo } from 'react';
import { IShipment } from "../models/interfaces";

interface ShipmentState {
    shipments: IShipment[];
    isLoading: boolean;
    error: string | null;
}

type ShipmentAction =
    | { type: 'FETCH_SHIPMENTS_START' | 'CREATE_SHIPMENT_START' | 'UPDATE_SHIPMENT_START' | 'DELETE_SHIPMENT_START' }
    | { type: 'FETCH_SHIPMENTS_SUCCESS'; payload: IShipment[] }
    | { type: 'CREATE_SHIPMENT_SUCCESS' | 'UPDATE_SHIPMENT_SUCCESS'; payload: IShipment }
    | { type: 'DELETE_SHIPMENT_SUCCESS'; payload: string }
    | { type: 'FETCH_SHIPMENTS_ERROR' | 'CREATE_SHIPMENT_ERROR' | 'UPDATE_SHIPMENT_ERROR' | 'DELETE_SHIPMENT_ERROR'; payload: string };

interface ShipmentContextType {
    state: ShipmentState;
    fetchShipments: (shipments: IShipment[]) => void;
    createShipment: (shipment: IShipment) => void;
    updateShipment: (shipment: IShipment) => void;
    deleteShipment: (shipmentId: string) => void;
    setError: (error: string) => void;
}

const ShipmentContext = createContext<ShipmentContextType | undefined>(undefined);

const initialState: ShipmentState = {
    shipments: [],
    isLoading: false,
    error: null,
};

function shipmentReducer(state: ShipmentState, action: ShipmentAction): ShipmentState {
    switch (action.type) {
        case 'FETCH_SHIPMENTS_START':
        case 'CREATE_SHIPMENT_START':
        case 'UPDATE_SHIPMENT_START':
        case 'DELETE_SHIPMENT_START':
            return { ...state, isLoading: true, error: null };
        case 'FETCH_SHIPMENTS_SUCCESS':
            return { ...state, isLoading: false, shipments: action.payload, error: null };
        case 'CREATE_SHIPMENT_SUCCESS':
            return { ...state, isLoading: false, shipments: [...state.shipments, action.payload], error: null };
        case 'UPDATE_SHIPMENT_SUCCESS':
            return {
                ...state,
                isLoading: false,
                shipments: state.shipments.map(s => s.id === action.payload.id ? action.payload : s),
                error: null
            };
        case 'DELETE_SHIPMENT_SUCCESS':
            return {
                ...state,
                isLoading: false,
                shipments: state.shipments.filter(s => s.id !== action.payload),
                error: null
            };
        case 'FETCH_SHIPMENTS_ERROR':
        case 'CREATE_SHIPMENT_ERROR':
        case 'UPDATE_SHIPMENT_ERROR':
        case 'DELETE_SHIPMENT_ERROR':
            return { ...state, isLoading: false, error: action.payload };
        default:
            return state;
    }
}

export const ShipmentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(shipmentReducer, initialState);

    const fetchShipments = useCallback((shipments: IShipment[]) => {
        dispatch({ type: 'FETCH_SHIPMENTS_START' });
        dispatch({ type: 'FETCH_SHIPMENTS_SUCCESS', payload: shipments });
    }, []);

    const createShipment = useCallback((shipment: IShipment) => {
        dispatch({ type: 'CREATE_SHIPMENT_START' });
        dispatch({ type: 'CREATE_SHIPMENT_SUCCESS', payload: shipment });
    }, []);

    const updateShipment = useCallback((shipment: IShipment) => {
        dispatch({ type: 'UPDATE_SHIPMENT_START' });
        dispatch({ type: 'UPDATE_SHIPMENT_SUCCESS', payload: shipment });
    }, []);

    const deleteShipment = useCallback((shipmentId: string) => {
        dispatch({ type: 'DELETE_SHIPMENT_START' });
        dispatch({ type: 'DELETE_SHIPMENT_SUCCESS', payload: shipmentId });
    }, []);

    const setError = useCallback((error: string) => {
        dispatch({ type: 'FETCH_SHIPMENTS_ERROR', payload: error });
    }, []);

    const contextValue = useMemo(() => ({
        state,
        fetchShipments,
        createShipment,
        updateShipment,
        deleteShipment,
        setError
    }), [state, fetchShipments, createShipment, updateShipment, deleteShipment, setError]);

    return (
        <ShipmentContext.Provider value={contextValue}>
            {children}
        </ShipmentContext.Provider>
    );
};

export const useShipmentContext = () => {
    const context = useContext(ShipmentContext);
    if (context === undefined) {
        throw new Error('useShipmentContext must be used within a ShipmentProvider');
    }
    return context;
};

export const useShipments = () => {
    const { state } = useShipmentContext();
    return useMemo(() => state.shipments, [state.shipments]);
};

export const useShipmentLoading = () => {
    const { state } = useShipmentContext();
    return state.isLoading;
};

export const useShipmentError = () => {
    const { state } = useShipmentContext();
    return state.error;
};