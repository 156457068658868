import React, { createContext, useContext, useReducer, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { LoginData, RegisterData } from '../types/types';
import AccountsService from "../services/accounts/AccountsService";
import { IUser } from "../models/interfaces";

interface AccountState {
    user: IUser | null;
    isLoading: boolean;
    error: string | null;
    isAuthenticated: boolean;
}

type AccountAction =
    | { type: 'LOGIN_START' | 'REGISTER_START' | 'LOGOUT' | 'REGISTER_SUCCESS' | 'CLEAR_ERROR' }
    | { type: 'LOGIN_SUCCESS' | 'UPDATE_USER'; payload: IUser }
    | { type: 'LOGIN_ERROR' | 'REGISTER_ERROR'; payload: string }
    | { type: 'SET_LOADING'; payload: boolean };

interface AccountContextType {
    state: AccountState;
    login: (data: LoginData) => Promise<void>;
    register: (data: RegisterData) => Promise<void>;
    logout: () => Promise<void>;
    updateUser: (user: IUser) => void;
    clearError: () => void;
    refreshUser: () => Promise<void>;
}

const AccountContext = createContext<AccountContextType | undefined>(undefined);

const initialState: AccountState = {
    user: null,
    isLoading: true,
    error: null,
    isAuthenticated: false,
};

function accountReducer(state: AccountState, action: AccountAction): AccountState {
    switch (action.type) {
        case 'LOGIN_START':
        case 'REGISTER_START':
            return { ...state, isLoading: true, error: null };
        case 'LOGIN_SUCCESS':
        case 'UPDATE_USER':
            return { ...state, isLoading: false, user: action.payload, error: null, isAuthenticated: true };
        case 'REGISTER_SUCCESS':
            return { ...initialState, isLoading: false };
        case 'LOGIN_ERROR':
        case 'REGISTER_ERROR':
            return { ...state, isLoading: false, error: action.payload, isAuthenticated: false };
        case 'LOGOUT':
            return { ...initialState, isLoading: false };
        case 'CLEAR_ERROR':
            return { ...state, error: null };
        case 'SET_LOADING':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}

export const AccountProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const initializeAuth = async () => {
            dispatch({ type: 'SET_LOADING', payload: true });
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                try {
                    const user = await AccountsService.getCurrentUser();
                    dispatch({ type: 'LOGIN_SUCCESS', payload: user });
                } catch (error) {
                    console.error('Failed to validate stored user:', error);
                    localStorage.removeItem('user');
                    dispatch({ type: 'LOGOUT' });
                }
            } else {
                dispatch({ type: 'SET_LOADING', payload: false });
            }
        };

        initializeAuth();
    }, []);

    const login = useCallback(async (data: LoginData) => {
        dispatch({ type: 'LOGIN_START' });
        try {
            const user = await AccountsService.login(data);
            localStorage.setItem('user', JSON.stringify(user));
            dispatch({ type: 'LOGIN_SUCCESS', payload: user });
        } catch (error) {
            dispatch({ type: 'LOGIN_ERROR', payload: error instanceof Error ? error.message : 'An unknown error occurred' });
            throw error;
        }
    }, []);

    const register = useCallback(async (data: RegisterData) => {
        dispatch({ type: 'REGISTER_START' });
        try {
            await AccountsService.register(data);
            dispatch({ type: 'REGISTER_SUCCESS' });
        } catch (error) {
            dispatch({ type: 'REGISTER_ERROR', payload: error instanceof Error ? error.message : 'An unknown error occurred' });
            throw error;
        }
    }, []);

    const logout = useCallback(async () => {
        try {
            await AccountsService.logout();
        } catch (error) {
            console.error('Logout failed:', error);
        } finally {
            localStorage.removeItem('user');
            dispatch({ type: 'LOGOUT' });
        }
    }, []);

    const updateUser = useCallback((user: IUser) => {
        localStorage.setItem('user', JSON.stringify(user));
        dispatch({ type: 'UPDATE_USER', payload: user });
    }, []);

    const clearError = useCallback(() => {
        dispatch({ type: 'CLEAR_ERROR' });
    }, []);

    const refreshUser = useCallback(async () => {
        if (state.isAuthenticated) {
            try {
                const user = await AccountsService.getCurrentUser();
                dispatch({ type: 'UPDATE_USER', payload: user });
            } catch (error) {
                console.error('Failed to refresh user:', error);
                // Optionally handle error, e.g., logout if token is invalid
                // logout();
            }
        }
    }, [state.isAuthenticated]);

    const contextValue = useMemo(() => ({
        state,
        login,
        register,
        logout,
        updateUser,
        clearError,
        refreshUser
    }), [state, login, register, logout, updateUser, clearError, refreshUser]);

    return (
        <AccountContext.Provider value={contextValue}>
            {children}
        </AccountContext.Provider>
    );
};

export const useAccountContext = () => {
    const context = useContext(AccountContext);
    if (context === undefined) {
        throw new Error('useAccountContext must be used within an AccountProvider');
    }
    return context;
};

// Helper hooks
export const useUser = () => {
    const { state } = useAccountContext();
    return useMemo(() => state.user, [state.user]);
};

export const useIsAuthenticated = () => {
    const { state } = useAccountContext();
    return useMemo(() => state.isAuthenticated, [state.isAuthenticated]);
};

export const useAuthLoading = () => {
    const { state } = useAccountContext();
    return state.isLoading;
};

export const useAuthError = () => {
    const { state } = useAccountContext();
    return state.error;
};