import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';

const API_URL = 'https://lawa-transit.fr/api/v1';
// const API_URL = 'http://127.0.0.1:8000/v1'
const TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';

class ApiService {
    private static instance: ApiService;
    private readonly api: AxiosInstance;

    private constructor() {
        this.api = axios.create({
            baseURL: API_URL,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        axiosRetry(this.api, { retries: 3 });

        this.api.interceptors.request.use(this.handleRequestUse);
        this.api.interceptors.response.use(
            (response) => response,
            this.handleResponseError
        );
    }

    public static getInstance(): ApiService {
        if (!ApiService.instance) {
            ApiService.instance = new ApiService();
        }
        return ApiService.instance;
    }

    private handleRequestUse = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token) {
            config.headers = config.headers || {};
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    };

    private handleResponseError = async (error: any) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const newAccessToken = await this.refreshToken();
                if (newAccessToken) {
                    this.setAuthHeader(newAccessToken);
                    return this.api(originalRequest);
                }
            } catch (refreshError) {
                console.error('Error refreshing token:', refreshError);
                this.redirectToLogin();
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    };

    private async refreshToken(): Promise<string | null> {
        const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
        if (!refreshToken) {
            return null;
        }
        try {
            const response = await axios.post<{ access: string }>(`${API_URL}/accounts/auth/token/refresh/`, {
                refresh: refreshToken,
            });
            const newAccessToken = response.data.access;
            localStorage.setItem(TOKEN_KEY, newAccessToken);
            return newAccessToken;
        } catch (error) {
            console.error('Failed to refresh token:', error);
            return null;
        }
    }

    private setAuthHeader(token: string): void {
        this.api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    private redirectToLogin(): void {
        // Implement your logic to redirect to login page
        console.log('Redirecting to login page...');
    }

    public async request<T>(method: string, url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        try {
            const response: AxiosResponse<T> = await this.api.request({
                method,
                url,
                data,
                ...config
            });
            return response.data;
        } catch (error) {
            console.error(`API ${method} request failed:`, error);
            throw error;
        }
    }

    public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        return this.request<T>('get', url, undefined, config);
    }

    public async post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        return this.request<T>('post', url, data, config);
    }

    public async put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        return this.request<T>('put', url, data, config);
    }

    public async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        return this.request<T>('delete', url, undefined, config);
    }

    public async patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        return this.request<T>('patch', url, data, config);
    }
}

export default ApiService.getInstance();