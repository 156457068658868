import React, { createContext, useContext, useReducer, ReactNode, useCallback, useMemo } from 'react';
import { IParcel } from "../models/interfaces";

interface ParcelState {
    parcels: IParcel[];
    isLoading: boolean;
    error: string | null;
}

type ParcelAction =
    | { type: 'FETCH_PARCELS_START' | 'CREATE_PARCEL_START' | 'UPDATE_PARCEL_START' | 'DELETE_PARCEL_START' }
    | { type: 'FETCH_PARCELS_SUCCESS'; payload: IParcel[] }
    | { type: 'CREATE_PARCEL_SUCCESS' | 'UPDATE_PARCEL_SUCCESS'; payload: IParcel }
    | { type: 'DELETE_PARCEL_SUCCESS'; payload: string }
    | { type: 'FETCH_PARCELS_ERROR' | 'CREATE_PARCEL_ERROR' | 'UPDATE_PARCEL_ERROR' | 'DELETE_PARCEL_ERROR'; payload: string };

interface ParcelContextType {
    state: ParcelState;
    fetchParcels: (parcels: IParcel[]) => void;
    createParcel: (parcel: IParcel) => void;
    updateParcel: (parcel: IParcel) => void;
    deleteParcel: (parcelId: string) => void;
    setError: (error: string) => void;
}

const ParcelContext = createContext<ParcelContextType | undefined>(undefined);

const initialState: ParcelState = {
    parcels: [],
    isLoading: false,
    error: null,
};

function parcelReducer(state: ParcelState, action: ParcelAction): ParcelState {
    switch (action.type) {
        case 'FETCH_PARCELS_START':
        case 'CREATE_PARCEL_START':
        case 'UPDATE_PARCEL_START':
        case 'DELETE_PARCEL_START':
            return { ...state, isLoading: true, error: null };
        case 'FETCH_PARCELS_SUCCESS':
            return { ...state, isLoading: false, parcels: action.payload, error: null };
        case 'CREATE_PARCEL_SUCCESS':
            return { ...state, isLoading: false, parcels: [...state.parcels, action.payload], error: null };
        case 'UPDATE_PARCEL_SUCCESS':
            return {
                ...state,
                isLoading: false,
                parcels: state.parcels.map(p => p.id === action.payload.id ? action.payload : p),
                error: null
            };
        case 'DELETE_PARCEL_SUCCESS':
            return {
                ...state,
                isLoading: false,
                parcels: state.parcels.filter(p => p.id !== action.payload),
                error: null
            };
        case 'FETCH_PARCELS_ERROR':
        case 'CREATE_PARCEL_ERROR':
        case 'UPDATE_PARCEL_ERROR':
        case 'DELETE_PARCEL_ERROR':
            return { ...state, isLoading: false, error: action.payload };
        default:
            return state;
    }
}

export const ParcelProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(parcelReducer, initialState);

    const fetchParcels = useCallback((parcels: IParcel[]) => {
        dispatch({ type: 'FETCH_PARCELS_START' });
        dispatch({ type: 'FETCH_PARCELS_SUCCESS', payload: parcels });
    }, []);

    const createParcel = useCallback((parcel: IParcel) => {
        dispatch({ type: 'CREATE_PARCEL_START' });
        dispatch({ type: 'CREATE_PARCEL_SUCCESS', payload: parcel });
    }, []);

    const updateParcel = useCallback((parcel: IParcel) => {
        dispatch({ type: 'UPDATE_PARCEL_START' });
        dispatch({ type: 'UPDATE_PARCEL_SUCCESS', payload: parcel });
    }, []);

    const deleteParcel = useCallback((parcelId: string) => {
        dispatch({ type: 'DELETE_PARCEL_START' });
        dispatch({ type: 'DELETE_PARCEL_SUCCESS', payload: parcelId });
    }, []);

    const setError = useCallback((error: string) => {
        dispatch({ type: 'FETCH_PARCELS_ERROR', payload: error });
    }, []);

    const contextValue = useMemo(() => ({
        state,
        fetchParcels,
        createParcel,
        updateParcel,
        deleteParcel,
        setError
    }), [state, fetchParcels, createParcel, updateParcel, deleteParcel, setError]);

    return (
        <ParcelContext.Provider value={contextValue}>
            {children}
        </ParcelContext.Provider>
    );
};

export const useParcelContext = () => {
    const context = useContext(ParcelContext);
    if (context === undefined) {
        throw new Error('useParcelContext must be used within a ParcelProvider');
    }
    return context;
};

export const useParcels = () => {
    const { state } = useParcelContext();
    return useMemo(() => state.parcels, [state.parcels]);
};

export const useParcelLoading = () => {
    const { state } = useParcelContext();
    return state.isLoading;
};

export const useParcelError = () => {
    const { state } = useParcelContext();
    return state.error;
};