import { useCallback } from 'react';
import { useAccountContext } from '../contexts/AccountContext';
import { LoginData, RegisterData } from '../types/types';
import { IUser } from "../models/interfaces";

export const useAuth = () => {
    const { state, login: contextLogin, register: contextRegister, logout: contextLogout, updateUser: contextUpdateUser, clearError: contextClearError } = useAccountContext();

    const login = useCallback(async (loginData: LoginData) => {
        try {
            await contextLogin(loginData);
        } catch (error) {
            // Handle error if needed
            throw error;
        }
    }, [contextLogin]);

    const register = useCallback(async (registerData: RegisterData) => {
        try {
            await contextRegister(registerData);
        } catch (error) {
            // Handle error if needed
            throw error;
        }
    }, [contextRegister]);

    const logout = useCallback(async () => {
        await contextLogout();
    }, [contextLogout]);

    const updateUser = useCallback((user: IUser) => {
        contextUpdateUser(user);
    }, [contextUpdateUser]);

    const clearError = useCallback(() => {
        contextClearError();
    }, [contextClearError]);

    return {
        user: state.user,
        isLoading: state.isLoading,
        error: state.error,
        isAuthenticated: state.isAuthenticated,
        login,
        register,
        logout,
        updateUser,
        clearError,
    };
};